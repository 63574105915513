<template>
  <div class="fitment-text">
    <van-nav-bar
      title="滚动文字"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="完成"
      @click-right="complete"
    />

    <van-field
      v-model="value"
      rows="2"
      autosize
      type="textarea"
      maxlength="50"
      placeholder="请输入"
      show-word-limit
    />
  </div>
</template>

<script>
export default {
  name: "fitmentText",
  data() {
    return {
      index: null,
      value: "",
    };
  },
  created() {
    this.index = +this.$route.query.index;
    // this.value = this.$store.state.fitment.list[this.index].childList;
    this.value = this.$store.state.fitment.editList[0].words;
  },
  methods: {
    // 完成
    complete() {
      if (!this.value) {
        this.$toast("请填写滚动文字");
      } else {
        this.$store.commit("setListValue", {
          index: this.index,
          value: [{ words: this.value }],
        });
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>